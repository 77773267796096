<template>
    <div class="guide-form-comp">
        <el-form :model="ruleForm" :rules="rules" label-position="top" ref="ruleForm" label-width="100px"
            class="demo-ruleForm">
            <el-row>
                <el-col>
                    <el-form-item label="固定资产类别" prop="categoryAssetVOList">
                        <el-cascader v-model="ruleForm.categoryAssetVOList" :options="categoryData"
                            :props="{ multiple: true }" collapse-tags style="width: 100%"
                            clearable></el-cascader></el-form-item>
                </el-col>
            </el-row>

            <el-row>
                <el-col>
                    <el-form-item label="折旧年限" prop="depreciationLife">
                        <el-input placeholder="请输入折旧年限" v-model="ruleForm.depreciationLife" show-word-limit><template
                                slot="append">年</template></el-input></el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col>
                    <el-form-item label="预计净残值率" prop="surplusRate">
                        <el-input placeholder="请输入预计净残值率" v-model="ruleForm.surplusRate" show-word-limit><template
                                slot="append">%</template></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-form-item align="right">
                    <el-button type="primary" @click="submitForm('ruleForm')" v-preventReClick>确定</el-button>
                    <el-button @click="resetForm">取消</el-button>
                </el-form-item>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import {
    addDepreciation, getPropertyList, getTableDetail
} from "@/api/ruge/ams/basicConfig/depreciationRules";
import { listToTree } from "@/utils/tree";
export default {
    props: {
        updateId: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            ruleForm: {
                categoryAssetVOList: [],
                depreciationLife: null,
                surplusRate: null,
            },
            rules: {
                categoryAssetVOList: [{ required: true, message: "固定资产类别", trigger: "change" },],
                depreciationLife: [
                    { required: true, message: "折旧年限为必填", trigger: "blur" },
                ],
                surplusRate: [
                    { required: true, message: "预计净残值率为必填", trigger: "blur" },
                ],

            },
            categoryData: []
        };
    },
    created() {
        console.log("xxx123123",this.updateId)
        if (this.updateId) {
            this.findDetail()
        }
        this.findPropertyList()
    },
    methods: {
        findDetail() {
            getTableDetail({
                depreciationId: this.updateId
            }).then((res) => {
                if (res.code === 200) {
                    this.ruleForm.depreciationLife = res.data.depreciationLife
                    this.ruleForm.surplusRate = res.data.surplusRate
                    this.ruleForm.categoryAssetVOList = res.data.categoryAssetVOList.map((item) => { return item.assetCategoryId })
                    console.log("this.ruleForm1",this.ruleForm)
                }
            });
        },
        findPropertyList() {
            getPropertyList({
                parentId: 'ROOT'
            }).then((res) => {
                if (res) {
                    let data = listToTree(res, "assetCategoryId", "parentId");
                    this.setTree(data)
                    console.log("data", data)
                }
            })
        },
        setTree(row) {
            let data = row.map((v) => {
                v.value = v.assetCategoryId;
                v.label = v.assetCategoryName;
                if (v.children != undefined) {
                    this.setTree(v.children);
                }
                return v;
            })
            this.categoryData = data;
            // console.log("v", data)
        },

        handleChange(value) {
            console.log(value);
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.submitHandler();
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        submitHandler() {
            console.log("this.ruleForm", this.ruleForm)
            let uList = []
            this.categoryData.forEach((item) => {
                this.ruleForm.categoryAssetVOList.forEach((adItem) => {
                    if (item.assetCategoryId == adItem) {
                        uList.push({
                            assetCategoryId: item.assetCategoryId,
                            assetCategoryName: item.assetCategoryName
                        })
                    }
                })
            })
            addDepreciation({
                depreciationId: this.updateId,
                depreciationLife: this.ruleForm.depreciationLife,
                surplusRate: this.ruleForm.surplusRate,
                categoryAssetVOList: uList
            }).then((res) => {
                if (res.code === 200) {
                    this.$message.success("操作成功！");
                    this.$emit("close", true);
                } else {
                    this.$message.warning("操作失败！");
                }
            });
        },
        resetForm() {
            this.$emit("close", false);
        },

    },
};
</script>

<style scoped lang="less"></style>